module.exports = [{
      plugin: require('../node_modules/@wardpeet/gatsby-plugin-static-site/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-MCMFG3L","includeInDevelopment":false,"defaultDataLayer":{"type":"function","value":"function () {\n\t\t\t\t\t/* if the url contains \"builder.preview\" is being rendered inside of an iframe (Editor mode) */\n\t\t\t\t\tconst editorMode = typeof window !== 'undefined' && window.location.href.indexOf('builder.preview') > -1;\n\t\t\t\t\treturn {\n\t\t\t\t\t\tdisable_GTM: editorMode,\n\t\t\t\t\t\tplatform: 'gatsby',\n\t\t\t\t\t\tisSpa: true\n\t\t\t\t\t};\n\t\t\t\t}"},"routeChangeEventName":"pageview","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../plugins/gatsby-plugin-dynatrace/gatsby-browser.js'),
      options: {"plugins":[],"enabled":true,"tenantId":"itw17423","applicationId":"APPLICATION-CDBFB9F0CF3EEADA","apiToken":"dt0c01.3IHDTCQQYRBOIVEGUPLMOFBW.YLRE4GUVIP6NRDPNDGU3YRSNOSLNZDCL4NDKPZE3NPGRGZ633TYDFT7ISHSVTAAH","mode":1},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
